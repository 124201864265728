@media only screen and (max-width: 600px) {
  .full-width {
    max-width: 100% !important;
  }

  .no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .no-radius {
    border-radius: 0px !important;
  }

  .small-padding-top {
    padding-top: 5px !important;
  }
}
